import React, { useEffect, useRef } from "react";
import "../../styles/bmRectangle.css";
import { Label } from "./bmText";
export const Box = () => {
    const parentRef = useRef<HTMLDivElement>(null);;
    const childRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (childRef.current && parentRef.current) {
          const childWidth = childRef.current.getBoundingClientRect().width;
          parentRef.current.style.minWidth = `${childWidth}px`;
        }
      }, []);


    return (
        <div className="box">
            <div className="rectangle" ref={parentRef} >
                <Label ref={childRef} />
            </div>
        </div>
    );
};
