import React from "react";
import "./styles/Style.css";
import bulb from './resources/flat-lay-composition-with-innovation-elements.png'
import { Box } from "./components/logobox/bmRectangle";

export const App = () => {
  const containerStyle = {
    backgroundImage: `url(${bulb})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    /* Other styles for your container */
    width: '100%',
    height: '100vh', // Adjust the height as needed
  };
    return (
      <div style={containerStyle}>
        <Box></Box>
      </div> 
    );
};
export default App;
