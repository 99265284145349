import React, { forwardRef } from "react";
import "../../styles/BMText.css";
import logo from "../../resources/BM-ico.png"

export const Label = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div className="label" ref={ref}>
            <img src={logo} className="logo" alt="Berkmont"/>
            <div className="text-wrapper">BERKMONT VENTURES</div>
        </div>
    );
});
